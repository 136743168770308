/* Other rules */

body {
  font-family: "Montserrat", sans-serif;
  background-color: #e9ecef;
  padding: 0;
  margin: 0;
}

.header {
  background-color: #fff;
  padding-top: 20px;
}

.nav img {
  height: 50px;
  padding: 0px;
  margin: 0px;
}

.nav {
  border-bottom: 1px solid lightgray;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 333;
}

.restaurant {
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.container {
  width: 80vw;
  margin: 0px auto;
}

/* Typographie */
p,
span {
  color: #495057;
  font-size: 14px;
}

h3 {
  font-size: 18px;
  color: #495057;
  margin: 0;
}

.title-para {
  font-size: 19px;
}

.price {
  font-weight: bold;
}

.pop {
  color: orange;
}

.cart p {
  font-size: 14px;
}

.total {
  font-size: 19px;
  font-weight: 700;
}

/* Image styles */
.mealpic-lg {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 5%;
  margin-left: 10px;
}

.hide-thumb {
  display: none;
}

.title-img {
  width: 400px;
  object-fit: cover;
  border-radius: 10px;
  margin-left: 0;
  padding: 0;
}

/* Content organisation, grid, flex etc */

.meal {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin: 15px 0px;
  border-radius: 5px;
  width: 48%;
  height: 170px;
  box-sizing: border-box;
}
.meal:hover {
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.meal ::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.meal-info {
  max-height: 40%;
  overflow-x: hidden;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.meals {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.category {
  display: grid;
  grid-template-columns: 1fr 380px;
}

/* Shopping Cart styles */
.cart {
  margin-top: 85px;
  margin-left: 30px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fff;
  border-radius: 10px;
  position: sticky;
  top: 80px;
  justify-self: end;
}

.cart > div:first-child {
  background-color: #00cdbd;
  padding: 0px 25px;
  margin-top: 10px;
  border-radius: 5px;
}
.cart > div:first-child h3 {
  color: white;
  line-height: 50px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.cart-meal {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* EMPTY CART */
.empty-cart {
  height: 150px;
}

.empty-cart p {
  text-align: center;
}

.empty-cart > div:first-child {
  background-color: gray !important;
  margin-bottom: 30px;
}

.cart-label:hover {
  cursor: pointer;
}

.cart-label-sm {
  display: none;
}

.cart-label-x {
  display: none;
}

.meal-name {
  padding-left: 5px;
}

.meal-title {
  display: flex;
}

.items-pan {
  max-height: 300px;
  overflow-y: auto;
}

.counter {
  color: #00cdbd;
  font-size: 16px;
  min-height: 100%;
}

.counter > * {
  padding: 0px 2px;
}

.sous-total {
  border-top: 1px solid #acb0bd;
  padding: 15px 0px;
  display: flex;
  justify-content: space-between;
}

.sous-total + div {
  display: flex;
  justify-content: space-between;
}

.sous-total + div span,
.sous-total span {
  font-size: 16px;
}

.total {
  margin: 10px 0px;
  border-top: 1px solid #acb0bd;
  padding: 10px 0px;
  font-size: 19px;
  display: flex;
  justify-content: space-between;
}

.footer {
  margin-top: 30px;
  padding: 5px;
  display: flex;
  justify-content: center;
  border-top: 1px solid white;
}

.foot-notes {
  font-size: 12px;
  line-height: 40px;
  color: #495057;
  text-decoration: none;
}

.foot-notes a {
  text-decoration: none;
}

/* ------------------------------------------------------------ */
/* Responsive Design.. Media Queries */
@media (max-width: 750px) {
  .hide-cart {
    display: none;
  }

  .cart {
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    max-height: 50vh;
  }

  .cart > div:first-child {
    background-color: #8f3c82;
    order: 3;
    margin-bottom: 10px;
  }

  .cart-label-sm {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cart-label-sm:hover {
    cursor: pointer;
  }

  .cart-label-x {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 10px;
  }

  .cart-label-sm > :first-child {
    background-color: #36054f;
    color: white;
    padding: 8px;
    font-size: 13px;
    line-height: 13px;
    font-weight: bold;
  }

  .cart-label {
    display: none;
  }

  .cart-pan {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999999999;
  }

  .category {
    display: grid;
    grid-template-columns: 100%;
  }

  .meals {
    display: grid;
    grid-template-columns: 100%;
  }

  .meal {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin: 15px 0px;
    border-radius: 5px;
    width: 100%;
    height: 170px;
  }
  .title-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin: 0px;
    padding: 0px;
    border-radius: 0%;
  }

  .restaurant {
    background-color: #fff;
    width: 100vw;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  .restaurant p {
    font-size: 16px;
  }

  .restaurant-title {
    padding: 20px;
  }
}

@media (min-width: 751px) and (max-width: 1108px) {
  .title-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin: 0px;
    padding: 0px;
    border-radius: 0%;
  }

  .restaurant {
    background-color: #fff;
    width: 100vw;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  .restaurant p {
    font-size: 16px;
  }

  .restaurant-title {
    padding: 20px;
  }

  .category {
    display: grid;
    grid-template-columns: 1fr 350px;
    justify-content: space-between;
  }

  .meals {
    display: grid;
    grid-template-columns: 100%;
  }

  .meal {
    width: 100%;
    height: 170px;
  }

  .container {
    width: 90vw;
    margin: 0px auto;
  }
}

@media (max-width: 1400px) {
  .container {
    width: 90vw;
  }
}
